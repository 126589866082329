 <template>
  <div class="create-layout">
    <el-dialog
      :title="$t('layout.Layout Selection')"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      width="820px">
      <div slot="title" class="dialog-header">
        <div class="dialog-index">2</div>
        <span class="dialog-title">{{$t('layout.Layout Selection')}}</span>
      </div>

      <el-form  
        :rules="rules" 
        ref="createGroup" 
        label-position="right" 
        label-width="10px"
        size="small"
      >
        <el-form-item label="" prop="groupName">
          <el-radio-group v-model="type">
            <el-radio label="MiddleLayout">{{$t('layout.Equated Rows and Coulmns')}}</el-radio>
            <el-radio label="DefaultLayout">{{$t('layout.Select Built In')}}</el-radio>
            <el-radio label="LayoutDiy">{{$t('layout.Custom Layout')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <layoutRowsAndCoulmns v-if="type == 'MiddleLayout'"  ref="MiddleLayout" :row="currentData" />
        <layoutDefault v-if="type == 'DefaultLayout'" ref="DefaultLayout" :row="currentData" /> 
        <LayoutDiy v-if="type == 'LayoutDiy'" ref="LayoutDiy" :row="currentData" /> 
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="medium" @click="handleClose">{{$t('button.Prior')}}</el-button>
        <el-button type="success" size="medium" @click="save">{{$t('button.Save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LayoutDiy from './layout-diy.vue'
import layoutRowsAndCoulmns from './layout-rows-and-coulmns.vue'
import layoutDefault from './layout-default.vue'

export default {
  name: "layoutModal",
  props: {
    row: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    LayoutDiy,
    layoutRowsAndCoulmns,
    layoutDefault
  },
  data() {
    return {
      radio: '1',
      dialogVisible: false,
      // formData: {
      //   storeName:''
      // },
      ids:[],
      type: 'MiddleLayout',
      selected: null,
      rules: {},
      currentData:{}
    }
  },
  mounted(){},
  methods: {
    open(){
      let data ={...this.row}
      this.currentData = data
      this.dialogVisible = true
    },
    // 关闭弹窗
    handleClose(){
      this.type = 'MiddleLayout'
      this.dialogVisible = false
    },
    Prior(){},
    // 保存按钮
    save(){
      if(this.row.layoutStrategyId){
        this.createLayout()
      } else {
        this.createStrategy()
      }
    },
    // 保存--创建策略
    async createStrategy(){
      let layoutLumpEntityList = []
      if(this.type == 'MiddleLayout'){
        layoutLumpEntityList = this.$refs.MiddleLayout.getLayout()
      } else if ( this.type == 'LayoutDiy' ){
        layoutLumpEntityList = this.$refs.LayoutDiy.getLayout()
      } else if ( this.type == 'DefaultLayout' ) {
        layoutLumpEntityList = this.$refs.DefaultLayout.getLayout()
      }
      const { layoutGroupHeight, layoutGroupWidth, layoutStrategyType, layoutStrategyName, layoutGroupId, scenarioId } = this.row
      console.log('==', { layoutStrategyType, layoutStrategyName, layoutLumpEntityList: layoutLumpEntityList})
      try{
        const { data: { layoutId, layoutStrategyId } } = await this.$Api.createStrategy({ layoutStrategyType, layoutStrategyName, layoutLumpEntityList: layoutLumpEntityList, layoutGroupId, scenarioId })
        // this.dialogVisible = false
        // this.$emit('layoutGetList')

        this.$router.push({
          name: 'layout-edit',
          params: {
            layoutLumpEntityList,
            width: layoutGroupWidth,
            height: layoutGroupHeight,
            layoutId,
            layoutStrategyId
          }
        })
      } catch (err) {
        const { message} = err;
        this.$message.error(message)
      }
    },
    // 保存--创建布局
    async createLayout(){
      console.log("保存布局",this.type)
      let layoutLumpEntityList = []
      if(this.type == 'MiddleLayout'){
        layoutLumpEntityList = this.$refs.MiddleLayout.getLayout()
      } else if ( this.type == 'LayoutDiy' ){
        layoutLumpEntityList = this.$refs.LayoutDiy.getLayout()
      } else if ( this.type == 'DefaultLayout' ) {
        layoutLumpEntityList = this.$refs.DefaultLayout.getLayout()
      }

      const { layoutGroupHeight, layoutGroupWidth} = this.row

      let params ={
        layoutStrategyId:this.row.layoutStrategyId,
        layoutLumpEntityList: layoutLumpEntityList
      }
      try {
        const { state,message, data: { layoutId, layoutStrategyId } } = await this.$Api.createLayout(params)
        if(state){
          this.$router.push({
            name: 'layout-edit',
            params: {
              layoutLumpEntityList,
              width: layoutGroupWidth,
              height: layoutGroupHeight,
              layoutId,
              layoutStrategyId
            }
          })
        } else {
          this.$message.error(message || '错误提示')
        }
        
      } catch (err) {
        console.log(err)
      }

    },
  },
}
</script>
<style lang="scss" scoped>
  .create-layout {
    .dialog-header {
      display: flex;
      align-items: center;
    }
    .dialog-index {
      width: 26px;
      height: 26px;
      background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
      border-radius: 0px 20px 20px 20px;
      text-align: center;
      line-height: 26px;
      font-size: 14px;
      font-family: Impact;
      font-weight: 400;
      color: #ffffff;
    }
    .dialog-title {
      margin-left: 18px;
      font-size: 15px;
      font-family: Verdana;
      font-weight: 400;
      color: #35b871;
    }
    ::v-deep .el-dialog__body{
      padding: 20px;
    }
    .header-box {
      border-top: 1px solid;
      padding: 20px; 
      .btn-box {
        margin-top: 20px
      }
    }
    table {
      border-collapse: collapse
    }
    table td {
      border: solid 1px #ccc;
      padding: 10px;
      word-break: break-all;
    }
    .cannotselect {
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      -khtml-user-select: none;
      user-select: none;
    }
    td.selected {
      background: #0094ff;
      color: #fff
    }
    ::v-deep .el-dialog__header {
      background-color: rgb(196, 238, 210);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    ::v-deep .el-dialog{
      margin-top: 5vh!important;
    }
    ::v-deep .dialog-footer .el-button{
      width: 110px;
    }
  }
</style>

