<template>
<div class="create-strategy">
  <el-dialog
    :title="$t('layout.Select layout policy type')"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    width="820px"
    class="create-strategy-dialog"
  >
    <div slot="title" class="dialog-header">
      <div class="dialog-index">1</div>
      <span class="dialog-title">{{$t('layout.Select layout policy type')}}</span>
    </div>
    <div class="create-strategy-bd">
      <div class="create-strategy-form">
        <el-form 
          :model="row" 
          :rules="rules" 
          ref="createStrategy" 
          label-position="right" 
          label-width="130px"
          size="small"
        >
          <el-form-item :label="$t('layout.Layout Strategy')" prop="layoutStrategyType">
            <el-radio-group v-model="row.layoutStrategyType" :disabled="!!row.layoutStrategyId">
              <el-radio :label="0">{{$t('layout.Normal')}}</el-radio>
              <el-radio :label="1">{{$t('layout.Split Screen')}}</el-radio>
              <el-radio :label="2">{{$t('layout.Self-Adaption')}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item :label="$t('layout.Strategy type')" prop="layoutStrategyType">
            <el-radio-group v-model="row.layoutStrategyType" :disabled="!!row.layoutStrategyId">
              <el-radio :label="0">{{$t('layout.Normal')}}</el-radio>
              <el-radio :label="1">{{$t('layout.Split Screen')}}</el-radio>
              <el-radio :label="2">{{$t('layout.Self-Adaption')}}</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item :label="$t('layout.Strategy Name')" prop="layoutStrategyName">
            <el-input v-model="row.layoutStrategyName" placeholder="" clearable></el-input>
          </el-form-item>
          <el-form-item :label="$t('layout.Scene')" class="margin_b0"  prop="scenarioId">
            <el-select v-model="row.scenarioId" :disabled="!!row.layoutStrategyId" placeholder="">
              <el-option
                v-for="item in store.scenarioList"
                :key="item.scenarioId"
                :label="item.translateTextName"
                :value="item.scenarioId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    
    <span slot="footer" class="dialog-footer">
      <el-button type="success" size="medium" @click="next">{{row.layoutStrategyId ? $t('button.Save'): $t('button.Next')}}</el-button>
    </span>
  </el-dialog>
  <createLayout ref="createLayout" :row="row"></createLayout>
</div>
</template>
<script>
  import { store } from "../store/index"
  import createLayout from './create-layout.vue'
  export default {
    // provide(){
    //   return {
    //       row: () => this.row
    //   }
    // },
    data() {
      return {
        store,
        formData: {
          layoutStrategyType: 0
        },
        dialogVisible: false,
        rules: {},
        row: {},
        rules: {
          layoutStrategyType: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' } 
          ],
          layoutStrategyName: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 50, message: this.$t('formValidate.maxLength50'), trigger: 'blur'}
          ],
          scenarioId: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'change' }
          ]
        }
      };
    },
    components: {
      createLayout,
    },
    created(){},
    methods: {
      // 打开弹窗
      async openDialog(row){
        console.log('row', row)
        let newData = {...row}
        this.row = newData
        try{
          await store.getScenarioList()
          this.dialogVisible = true
        } catch (e) {
          console.log('捕获报错',e)
        }
        
      },
      // 关闭弹窗
      handleClose(){
        this.$refs.createStrategy.resetFields()
        this.dialogVisible = false
      },
      // 点击下一步
      next(){
        this.$refs.createStrategy.validate( valid => {
          if (valid) {
            this.updateStrategy()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 更新布局策略名称
      async updateStrategy(){
        const { layoutStrategyId, layoutStrategyName,layoutStrategyType } = this.row
        if(layoutStrategyId){
          await this.$Api.updateStrategy({ layoutStrategyId, layoutStrategyName })
          this.dialogVisible = false
          this.$emit('layoutGetList')
        } else {
          if(layoutStrategyType == 0){
            // 选择了普通布局策略
            this.createStrategy()
          } else {
            // this.$refs.createLayout.dialogVisible = true
            this.$refs.createLayout.open()
          }
          
        }
      },
      // 当选择普通布局时，直接创建步骤策略
      async createStrategy(){
        let {layoutGroupId,scenarioId,layoutStrategyType,layoutGroupHeight, layoutGroupWidth,layoutStrategyName} = this.row
        let layoutLumpEntityList=[{
          endCellIndex: layoutGroupWidth,
          endRowIndex: layoutGroupHeight,
          sort: 1,
          startCellIndex: 0,
          startRowIndex: 0,
        }]
        let params = {
          layoutGroupId, 
          scenarioId,
          layoutStrategyType, 
          layoutStrategyName, 
          layoutLumpEntityList, 
        }
        try {
          const { data: { layoutId, layoutStrategyId } } = await this.$Api.createStrategy(params)
          this.dialogVisible = false
          this.$router.push({
            name: 'layout-edit',
            params: {
              layoutLumpEntityList,
              width: layoutGroupWidth,
              height: layoutGroupHeight,
              layoutId,
              layoutStrategyId
            }
          })

        } catch (err) {
          const { message} = err;
          this.$message.error(message)
        }
       
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .create-strategy{
    .dialog-header {
      display: flex;
      align-items: center;
    }
    .dialog-index {
      width: 26px;
      height: 26px;
      background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
      border-radius: 0px 20px 20px 20px;
      text-align: center;
      line-height: 26px;
      font-size: 14px;
      font-family: Impact;
      font-weight: 400;
      color: #ffffff;
    }
    .dialog-title {
      margin-left: 18px;
      font-size: 15px;
      font-family: Verdana;
      font-weight: 400;
      color: #35b871;
    }
    .create-strategy-bd{
      margin:-28px -115px 0;
      .el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 56%;
      }
    }
    .create-strategy-form{
      width: 500px;
      margin: 0 auto;
      margin-top: 30px;
    }
    
    .margin_b0 {
      margin-bottom: 0;
    }
    .el-select{
      width: 100%;
    }

    // 重置样式
    ::v-deep .el-button{
      width: 110px;
    }
    .el-button--success{
      background: #35B871;
    }
    ::v-deep .el-dialog__header{
      background-color: rgb(196, 238, 210);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

</style>
