 <template>
 <div  class="carouselBox">
    <el-carousel indicator-position="none" :autoplay="false">
      <el-carousel-item v-for="pageIndex in 3" :key="pageIndex" >
        <div v-for="it in 7" :key="it"  @click="itemChange((pageIndex-1)*7 + it - 1)"
        :class="[
          'icon',
          `icon--${index===(pageIndex-1)*7 + it -1}`
        ]"
        >
          <div style="text-align: center;">{{(pageIndex-1)*7 + it}}</div>
          <icon-svg :name="`layout${(pageIndex-1)*7 + it}`"></icon-svg> 
				</div>
      </el-carousel-item>
    </el-carousel>
    <div class="layout">
      <template v-if="index === null">
        <div class="default-view"></div>
      </template>
      <icon-svg v-else :name="`layout${index+1}`"></icon-svg> 
    </div>
</div>
</template>

<script>
import defaultLayout from './layout-default-data.js'
export default {
  name: "layoutDiy",
  props: {
    row: {
      type: Object,
      default: () => {}
    }
  },
  components: {
  },
  data() {
    return {
      flag: true,
      splitForm: {
        rows: '',
        columns: ''
      },
      dataList: [{
          startCellIndex: 0,
          endCellIndex: 3,
          startRowIndex: 0,
          endRowIndex: 2,
          type: 0,
          item: [1,2]
        },
        {
          startCellIndex: 0,
          endCellIndex: 3,
          startRowIndex: 0,
          endRowIndex: 2,
          type: 1,
          item: [2,1]
        },
        {
          startCellIndex: 0,
          endCellIndex: 3,
          startRowIndex: 0,
          endRowIndex: 2,
          type: 2,
          item: [1,1],
          hasChild: true
        },
        {
          startCellIndex: 0,
          endCellIndex: 3,
          startRowIndex: 0,
          endRowIndex: 2,
          type: 3
        },
        {
          startCellIndex: 0,
          endCellIndex: 3,
          startRowIndex: 0,
          endRowIndex: 2,
          type: 4
        },
        {
          startCellIndex: 0,
          endCellIndex: 3,
          startRowIndex: 0,
          endRowIndex: 2,
          type: 5
        },
        {
          startCellIndex: 0,
          endCellIndex: 3,
          startRowIndex: 0,
          endRowIndex: 2,
          type: 6
        },
        {
          startCellIndex: 0,
          endCellIndex: 3,
          startRowIndex: 0,
          endRowIndex: 2,
          type: 7
        },
        {
          startCellIndex: 0,
          endCellIndex: 3,
          startRowIndex: 0,
          endRowIndex: 2,
          type: 8
        }
      ],
      // dataList: [],
      index: null
    }
  },
  computed: {},
  mounted(){},
  methods: {
    itemChange(index){
      console.log('index', index)
      this.index = index 
    },
    getLayout(){
      if(this.index === null){
        this.$message.error(this.$t('layout.ErrorMessage4'))
        return
      }
      console.log('----getLayout',this.index)
      const { layoutGroupHeight, layoutGroupWidth } = this.row
      const fn = defaultLayout[this.index]
      return fn(layoutGroupWidth, layoutGroupHeight)
    }
  },
}
</script>
<style lang="scss" scoped>
.carouselBox {
  border: 1px solid #E1E1E1;
  border-radius: 6px;
  .default-view{
    width:276px;
    height:276px;
    margin: 0 auto;
    background: #DDDDDD;
    border-radius: 4px;
  }
  .layout {
    height: 352px;
    width: 100%;
    padding: 40px 0;
    text-align: center;
    .svg-icon {
      height: 276px;
      width: 276px;
      color: #DDDDDD;
    }
  }
  ::v-deep .el-carousel__container {
    height: 107px;
    background-color: #F8F8F8;
    .el-carousel__item {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    .icon {
      cursor: pointer;
      margin: 0 10px;
      &.icon--true{
        color: #3461FF;
        .svg-icon{
          fill:#3461FF;
        }
      }
      .svg-icon {
        width: 64px;
        height: 64px;
        // color: #DDDDDD;
        color: #3461FF;
      }
    }
    
  }
}
  
</style>

