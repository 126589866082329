export const getLayoutItem = ([rows, columns],[width,height]) => {
  const rowNum = Math.floor(height / rows)
  const colNum = Math.floor(width / columns)
  console.log('rowNum colNum', rowNum, colNum)
  let layout = []
  let index = 1
  for(let r=0; r < rows; r++){
    for(let c=0; c < columns; c++){
      layout.push({
        startCellIndex: c*colNum,
        endCellIndex: (c+1)*colNum,
        startRowIndex: r*rowNum,
        endRowIndex: (r+1)*rowNum,
        sort: index++
      })
    }
  }
  console.log('layout', layout)
  return layout
}

const data =  [
    (width, height) => {
      //第1种
      return getLayoutItem([1,2],[width, height])
    },
    (width, height) => {
      //第2种
      return getLayoutItem([2,1],[width, height])
    },
    (width, height) => {
      //第3
      return [
          {
            startCellIndex: 0,
            endCellIndex: Math.floor(width / 3),
            startRowIndex: 0,
            endRowIndex:  height,
            sort: 1,
          },
          {
            startCellIndex: Math.floor(width / 3),
            endCellIndex: Math.floor(width / 3)*3,
            startRowIndex: 0,
            endRowIndex:  height,
            sort: 2,
          }
      ]
    },
    (width, height) => {
      //第4
      return [
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3)*2,
              startRowIndex: 0,
              endRowIndex:  height,
              sort: 1,
          },
          {
              startCellIndex: Math.floor(width / 3)*2,
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: 0,
              endRowIndex:  height,
              sort: 2,
          }
      ]
    },
    (width, height) => {
      //第5
      return [
          {
              startCellIndex: 0,
              endCellIndex: width,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3),
              sort:1,
          },
          {
              startCellIndex: 0,
              endCellIndex: width,
              startRowIndex: Math.floor(height / 3),
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 2,
          }
      ]
    },
    (width, height) => {
      //第6
      return [
          {
              startCellIndex: 0,
              endCellIndex: width,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3)*2,
              sort: 1,
          },
          {
              startCellIndex: 0,
              endCellIndex: width,
              startRowIndex: Math.floor(height / 3)*2,
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 2,
          }
      ]
    },
    //第二行
    (width, height) => {
      //第7
      return getLayoutItem([3,1],[width, height])
    },
    (width, height) => {
      //第8
      return getLayoutItem([1,3],[width, height])
    },
    (width, height) => {
      //第9
      return [
          {
              startCellIndex: 0,
              endCellIndex: width,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 2),
              sort: 1,
          },
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 2),
              startRowIndex: Math.floor(height / 2),
              endRowIndex:  Math.floor(height / 2)*2,
              sort: 2,
          },
          {
              startCellIndex: Math.floor(width / 2),
              endCellIndex: Math.floor(width / 2)*2,
              startRowIndex:  Math.floor(height / 2),
              endRowIndex:  Math.floor(height / 2)*2,
              sort: 3,
          }
      ]
    }, 
    (width, height) => {
      //第10
      return [
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 2),
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 2),
              sort: 1,
          },
          {
              startCellIndex: Math.floor(width / 2),
              endCellIndex: Math.floor(width / 2)*2,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 2),
              sort: 2,
          },
          {
              startCellIndex: 0,
              endCellIndex:  Math.floor(width / 2)*2,
              startRowIndex:  Math.floor(height / 2),
              endRowIndex:  Math.floor(height / 2)*2,
              sort: 3,
          }
      ]
    },  
    // 第11种--修复
    (width, height) => {
      return [
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 2),
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 2),
              sort: 1,
          },
          {
              startCellIndex: Math.floor(width / 2),
              endCellIndex: Math.floor(width / 2)*2,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 2)*2,
              sort: 2,
          },
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 2),
              startRowIndex:  Math.floor(height / 2),
              endRowIndex:  Math.floor(height / 2)*2,
              sort: 3,
          }
      ]
    },  
    (width, height) => {
      //第12种
      return [
          {
            startCellIndex: 0,
            endCellIndex: Math.floor(width / 2),
            startRowIndex: 0,
            endRowIndex:  Math.floor(height / 2)*2,
            sort: 1,
          },
          {
            startCellIndex: Math.floor(width / 2),
            endCellIndex: Math.floor(width / 2)*2,
            startRowIndex: 0,
            endRowIndex:  Math.floor(height / 2),
            sort: 2,
          },
          {
            startCellIndex: Math.floor(width / 2),
            endCellIndex: Math.floor(width / 2)*2,
            startRowIndex:  Math.floor(height / 2),
            endRowIndex:  Math.floor(height / 2)*2,
            sort: 3,
          }
      ]
    },
    //第三行
    (width, height) => {
      //第13
      return getLayoutItem([2,2],[width, height])
    },
    (width, height) => {
      //第14
      return [
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3),
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3),
              sort: 1,
          },
          {
              startCellIndex: Math.floor(width / 3),
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 2,
          },
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3),
              startRowIndex: Math.floor(height / 3),
              endRowIndex:  Math.floor(height / 3) *2,
              sort: 3,
          },
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3),
              startRowIndex: Math.floor(height / 3)*2,
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 4,
          }
      ]
    },
    (width, height) => {
      //第15
      return [
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3)*2,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 1,
          },
          {
              startCellIndex: Math.floor(width / 3)*2,
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3),
              sort: 2,
          },
          {
              startCellIndex: Math.floor(width / 3)*2,
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: Math.floor(height / 3),
              endRowIndex:  Math.floor(height / 3)*2,
              sort: 3,
          },
          {
              startCellIndex: Math.floor(width / 3)*2,
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: Math.floor(height / 3)*2,
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 4,
          }
      ]
    },
    (width, height) => { 
      //第16  top三列
      return [
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3),
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3),
              sort: 1,
          },
          {
              startCellIndex: Math.floor(width / 3),
              endCellIndex: Math.floor(width / 3)*2,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3),
              sort: 2,
          },
          {
              startCellIndex: Math.floor(width / 3)*2,
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3),
              sort: 3,
          },
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: Math.floor(height / 3),
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 4,
          },
      ]
    },
    (width, height) => { 
      //第17   bottom三列
      return [
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3)*2,
              sort: 1,
          },
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3),
              startRowIndex: Math.floor(height / 3)*2,
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 2,
          },
          {
              startCellIndex: Math.floor(width / 3),
              endCellIndex: Math.floor(width / 3)*2,
              startRowIndex: Math.floor(height / 3)*2,
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 3,
          },
          {
              startCellIndex: Math.floor(width / 3)*2,
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: Math.floor(height / 3)*2,
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 4,
          },
      ]
    },
    (width, height) => {
      //第18
      return [
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3),
              sort: 1,
          },
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3),
              startRowIndex: Math.floor(height / 3),
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 2,
          },
          {
              startCellIndex: Math.floor(width / 3),
              endCellIndex: Math.floor(width / 3)*2,
              startRowIndex: Math.floor(height / 3),
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 3,
          },
          {
              startCellIndex: Math.floor(width / 3)*2,
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: Math.floor(height / 3),
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 4,
          },
      ]
    },
    //第四行
    // 第19种样式修复
    (width, height) => { //1
      return [
        {
          startCellIndex: 0,
          endCellIndex: Math.floor(width / 3),
          startRowIndex: 0,
          endRowIndex:  Math.floor(height / 3)*2,
          sort: 1,
        },
        {
          startCellIndex: Math.floor(width / 3),
          endCellIndex: Math.floor(width / 3)*2,
          startRowIndex: 0,
          endRowIndex:  Math.floor(height / 3)*2,
          sort: 2,
        },
        {
          startCellIndex: Math.floor(width / 3)*2,
          endCellIndex: Math.floor(width / 3)*3,
          startRowIndex: 0,
          endRowIndex:  Math.floor(height / 3)*2,
          sort: 3,
        },
        {
          startCellIndex: 0,
          endCellIndex: Math.floor(width / 3)*3,
          startRowIndex: Math.floor(height / 3)*2,
          endRowIndex:  Math.floor(height / 3)*3,
          sort: 4,
        },
      ]
    },
    (width, height) => { //2
      //第20
      return [
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3),
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 1,
          },
          {
              startCellIndex: Math.floor(width / 3),
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3),
              sort: 2,
          },
          {
              startCellIndex: Math.floor(width / 3),
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: Math.floor(height / 3),
              endRowIndex:  Math.floor(height / 3)*2,
              sort: 3,
          },
          {
              startCellIndex: Math.floor(width / 3),
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: Math.floor(height / 3)*2,
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 4,
          }
      ]
    },
    // 第21种样式修复
    (width, height) => { //2
      return [
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3)*2,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3)*1,
              sort: 1,
          },
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3)*2,
              startRowIndex: Math.floor(height / 3),
              endRowIndex:  Math.floor(height / 3)*2,
              sort: 2,
          },
          {
              startCellIndex: 0,
              endCellIndex: Math.floor(width / 3)*2,
              startRowIndex: Math.floor(height / 3)*2,
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 3,
          },
          {
              startCellIndex: Math.floor(width / 3)*2,
              endCellIndex: Math.floor(width / 3)*3,
              startRowIndex: 0,
              endRowIndex:  Math.floor(height / 3)*3,
              sort: 4,
          }
      ]
    },
]
export default data