 <template>
 <div class="layout-diy" >
  <!-- :style="{width: row.layoutGroupWidth+'px'}"  -->
    <div class="header-box">
      <div class="btn-box">
       <el-button @click="megerCells">{{$t('layout.Merge')}}</el-button>
       <el-button @click="breakCells">{{$t('layout.Break Up')}}</el-button>
      </div>
      <div class="table_box">
      <!-- :style="{
          width: row.layoutGroupWidth+'px',
          height: row.layoutGroupHeight+'px',
        }" -->
        <table border="1" id="tb1" cellpadding="0" cellspacing="0" class="table-cell">
          <tr v-for="(_tr, trIndex) in Math.ceil(row.layoutGroupHeight/10)" :key="trIndex" :data-index="trIndex">
            <td v-for="(_td, tdIndex) in Math.ceil(row.layoutGroupWidth/10)" :key="tdIndex" :id="'t' + tdIndex"></td>
          </tr>
        </table>  
      </div>
    </div>
</div>
</template>

<script>

let table = null
let startTD, endTD, MMRC = { startRowIndex: -1, startCellIndex: -1, endRowIndex: -1, endCellIndex: -1 }, totalCell=0;
export default {
  name: "layoutDiy",
  props: {
    row: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {
      width: '',
      height: '',
      formData: {
        storeName:''
      },
    }
  },
  created() {},
  mounted(){
    table = document.querySelector('#tb1')
    this.initTdRc(table)
    table.addEventListener('mousedown', this.onMousedown);
  },
  methods: {
    getLayout(){
      console.log('table==', table)
      let index = 1
      let layoutBlock = []
      for (let tr of table.rows) {
          for (let td of Array.from(tr.cells)) {
            const rc = JSON.parse(td.getAttribute('rc'))
            
            const rowspan = td.getAttribute('rowspan')
            const colspan = td.getAttribute('colspan')
            if(rowspan > 1 || colspan > 1){
                console.log("rc",rc)
                rc.startCellIndex = rc.startCellIndex*10
                rc.startRowIndex = rc.startRowIndex*10
                rc.endCellIndex = (rc.endCellIndex+1)*10
                rc.endRowIndex = (rc.endRowIndex+1)*10
                layoutBlock.push({ ...rc, sort: index++ })
            }
          }
      }
      console.log('layoutBlock==', layoutBlock)
      //   this.$router.push({
      //     name: 'layout-edit',
      //     params: {layoutBlock}
      //   })
      if(layoutBlock.length === 0){
        this.$message.error(this.$t('layout.ErrorMessage3'))
        return
      }
      return layoutBlock
    },
    getRC(curTd/*:HTMLTableCellElement*/, totalCell/*:int*/) {
        let tbody = curTd.parentNode.parentNode;
        //从第一行计算总的td数量，最后一行也可以，其他行计算不准确
        let cellIndex = -1;
        let rowIndex = curTd.parentNode.rowIndex;
        if (curTd.parentNode.cells.length == totalCell) {//没有被rowspan,colspan影响到的单元格
          cellIndex = curTd.cellIndex;
        }
        else {
          //被rowspan影响，往上找rowspan的行
          cellIndex = curTd.cellIndex;
          for (let i = rowIndex - 1; i >= 0; i--) {
            for (let td of tbody.rows[i].cells) {
              if (td.rowSpan > 1) {
                if (td.parentNode.rowIndex + td.rowSpan > rowIndex && curTd.offsetLeft > td.offsetLeft) {//curTd所在行下标和当前rowspan重合，并且处于curTd前（使用位置来定位）
                  cellIndex += td.colSpan;//加上次单元格colSpan
                }
              }
            }
          }
          //同一行中td的colspan合并计算
          for (let i = curTd.cellIndex - 1; i >= 0; i--) {
            cellIndex += curTd.parentNode.cells[i].colSpan - 1;
          }
        }
        return JSON.stringify({ startRowIndex: rowIndex, startCellIndex: cellIndex, endRowIndex: rowIndex + curTd.rowSpan - 1, endCellIndex: cellIndex + curTd.colSpan - 1 });
    },
    //初始表格的原始rowIndex，cellIndex及跨行，跨列信息
    initTdRc() {
      table.classList.add('cannotselect');
      let firstRow = table.rows[0]; //用第一行计算总的td数量，其他行计算不准确
      totalCell = 0;
      for (let td of firstRow.cells) {
        totalCell += td.colSpan;
      }
      for (let i = 0; i < table.rows.length; i++) {
        for (let j = 0; j < table.rows[i].cells.length; j++) {
          table.rows[i].cells[j].setAttribute('id', j);
          // table.rows[i].cells[j].setAttribute('style', "width:10px;height:10px;border: 1px solid #ccc;");
          table.rows[i].cells[j].setAttribute('rc', this.getRC(table.rows[i].cells[j], totalCell));
        }
      }
    },
    //删除td选中样式
    removeAllSelectedClass() {
      for (let tr of table.rows) {
        for (let td of tr.cells) {
          td.classList.remove('selected')
        }
      }
    },
    //在范围内td添加选中高亮样式
    addSelectedClass() {
      for (let tr of table.rows) {
        for (let td of tr.cells) {
          let rc = JSON.parse(td.getAttribute('rc')/* as string*/);
          //在范围内加上高亮样式
          if (rc.startRowIndex >= MMRC.startRowIndex && rc.endRowIndex <= MMRC.endRowIndex && rc.startCellIndex >= MMRC.startCellIndex && rc.endCellIndex <= MMRC.endCellIndex) {
            td.classList.add('selected')
          }
        }
      }
    },
    //检查选中范围的rowspan和colspan
    checkMMRC() {
      let rangeChange = false;
      for (let tr of table.rows) {
        for (let td of tr.cells) {
          let rc = JSON.parse(td.getAttribute('rc')/* as string*/);
          //判断单元格4个顶点是否在范围内
          if (
            (rc.startRowIndex >= MMRC.startRowIndex && rc.startRowIndex <= MMRC.endRowIndex && rc.startCellIndex >= MMRC.startCellIndex && rc.startCellIndex <= MMRC.endCellIndex) ||//左上
            (rc.endRowIndex >= MMRC.startRowIndex && rc.endRowIndex <= MMRC.endRowIndex && rc.startCellIndex >= MMRC.startCellIndex && rc.startCellIndex <= MMRC.endCellIndex) ||//左下
            (rc.startRowIndex >= MMRC.startRowIndex && rc.startRowIndex <= MMRC.endRowIndex && rc.endCellIndex >= MMRC.startCellIndex && rc.endCellIndex <= MMRC.endCellIndex) ||//右上
            (rc.endRowIndex >= MMRC.startRowIndex && rc.endRowIndex <= MMRC.endRowIndex && rc.endCellIndex >= MMRC.startCellIndex && rc.endCellIndex <= MMRC.endCellIndex) //右下
          ) {//debugger
            let startRowIndex = Math.min.call(null, MMRC.startRowIndex, rc.startRowIndex);
            let endRowIndex = Math.max.call(null, MMRC.endRowIndex, rc.endRowIndex);
            let startCellIndex = Math.min.call(null, MMRC.startCellIndex, rc.startCellIndex);
            let endCellIndex = Math.max.call(null, MMRC.endCellIndex, rc.endCellIndex);
            if (MMRC.startRowIndex > startRowIndex) {
              MMRC.startRowIndex = startRowIndex;
              rangeChange = true;
            }
            if (MMRC.startCellIndex > startCellIndex) {
              MMRC.startCellIndex = startCellIndex;
              rangeChange = true;
            }
            if (MMRC.endRowIndex < endRowIndex) {
              MMRC.endRowIndex = endRowIndex;
              rangeChange = true;
            }
            if (MMRC.endCellIndex < endCellIndex) {
              MMRC.endCellIndex = endCellIndex;
              rangeChange = true;
            }
          }
        }
      }
      //范围有变化继续扩展
      if (rangeChange) {
        this.checkMMRC(table);
      }
    },
    //鼠标按下事件
    onMousedown(e/*:any*/) {
      console.log('e===', e)
      // console.log('e=tagName==', e.target.tagName)
      let o = e.target;
      if (o.tagName == 'TD') {
        this.removeAllSelectedClass(table);
        //绑定事件
        endTD = startTD = o;
        table.addEventListener('mousemove', this.onMousemove);
        table.addEventListener('mouseup', this.onMouseup);
        startTD.classList.add('selected');
        MMRC = JSON.parse(o.getAttribute('rc')/*as string*/);
      }
    },
    //鼠标在表格单元格内移动事件
    onMousemove(e/*:any*/) {
      let o = e.target;//as HTMLTableCellElement
      console.log('onMousemove=tagName==',o.tagName)
      console.log('onMousemove=startTD==',startTD)
      console.log('onMousemove=endTD==',endTD)
      console.log('onMousemove=0==',o)
      if (o.tagName == 'TD' && ((endTD != o && startTD != o) || (endTD && startTD == o))) {//不在开始td和结束td移动时再触发检查，优化下
        endTD = o;
        this.removeAllSelectedClass(table);

        let startRC = JSON.parse(startTD.getAttribute('rc')/* as string*/), endRC = JSON.parse(endTD.getAttribute('rc')/* as string*/);
        //求2个单元格的开始rowIndex，结束rowIndex，开始cellIndex和结束cellIndex
        let startRowIndex = Math.min.call(null, startRC.startRowIndex, endRC.startRowIndex);
        let endRowIndex = Math.max.call(null, startRC.endRowIndex, endRC.endRowIndex);
        let startCellIndex = Math.min.call(null, startRC.startCellIndex, endRC.startCellIndex);
        let endCellIndex = Math.max.call(null, startRC.endCellIndex, endRC.endCellIndex);

        MMRC = { startRowIndex, startCellIndex, endRowIndex, endCellIndex };

        this.checkMMRC(table);
        this.addSelectedClass(table);
      }
    },
    onMouseup() {
      table.removeEventListener('mousemove', this.onMousemove);
      table.removeEventListener('mouseup', this.onMouseup);
    },
    //合并单元格
    megerCells(/*:HTMLTableElement*/) {
      console.log('合并-----',startTD,endTD)
      if(!startTD && !endTD){
        this.$message.error(this.$t('layout.ErrorMessage1'))
      }
      if (startTD && endTD && startTD != endTD) {//开始结束td不相同确认合并
        let tds = Array.from(table.querySelectorAll('td.selected'))
        let firstTD = tds[0]
        // let html = this.getText();

        for (let i = 1; i < tds.length; i++) {
          tds[i].parentNode.removeChild(tds[i]);
        }
        // firstTD.innerHTML = html;
        const width =  MMRC.endCellIndex - MMRC.startCellIndex + 1
        const height =  MMRC.endRowIndex - MMRC.startRowIndex + 1
        //更新合并的第一个单元格的缓存rc数据为所跨列和行
        firstTD.setAttribute('colspan', width)
        firstTD.setAttribute('rowspan', height);
        console.log('firstTD===', firstTD)
        firstTD.style.width = `${width*10}px`
        firstTD.style.height = `${height*10}px`
        // firstTD.style.padding = `${height*2}px ${width*2}px`

        firstTD.setAttribute('rc', JSON.stringify(MMRC));

      }
      this.removeAllSelectedClass(table);
      MMRC = startTD = endTD = null;
    },
    getInsertCellIndex(nextTr, offsetRight/*:int*/) {
      //找到拆分单元格时出现rowspan插入到新行中的单元格下标
      for (let td of nextTr.cells) {
        if (Math.abs(td.offsetLeft - offsetRight) < 2)
          //注意这里内容宽度会出现小数点，但是用offsetWidth取值是整数有舍入操作，所以要取差值
          return td.cellIndex;
      }
      return 0//找不到说明是在第一列合并的，返回0
    },
    // 打散-----
    breakCells() {
      console.log('拆分',MMRC)
      if(!MMRC){
        // this.$message.error('请先选择需要打散的区域')
        this.$message.error(this.$t('layout.ErrorMessage2'))
        return
      }
      if (MMRC) {
        if (MMRC.startRowIndex == MMRC.endRowIndex && MMRC.startCellIndex == MMRC.endCellIndex) {
          // alert('无法拆分！');
          this.$message.error(this.$t('layout.ErrorMessage2'))
          return
        }
        var rows = Array.from(table.rows),
          cells;
        for (let tr of rows) {
          cells = Array.from(tr.cells);//拷贝到数组，而不是直接遍历tr.cells，cells会受cellspan，rowspan影响
          for (let td of cells) {
            let rc = JSON.parse(td.getAttribute('rc')/* as string*/);
            td.style = ''
            if (!rc) continue;//rowspan新增的单元格跳过
            //在范围内
            if (rc.startRowIndex >= MMRC.startRowIndex && rc.endRowIndex <= MMRC.endRowIndex && rc.startCellIndex >= MMRC.startCellIndex && rc.endCellIndex <= MMRC.endCellIndex) {
              let colSpan = rc.endCellIndex - rc.startCellIndex;
              if (colSpan > 0) {
                //跨列
                for (let i = 0, j = colSpan; i < j; i++) {
                  tr.insertCell(td.cellIndex+1);//这个是在后面插入，前面插入+1
                }
                td.colSpan = 1;
              }
              let rowSpan = rc.endRowIndex - rc.startRowIndex;
              if (rowSpan > 0) {
                //跨行
                for (let k = 1; k <= rowSpan; k++) {
                  let nextTr = table.rows[rc.startRowIndex + k];
                  let cellIndex = this.getInsertCellIndex(nextTr, td.offsetLeft+td.offsetWidth);
                  for (let i = 0; i < colSpan + 1; i++) {
                    nextTr.insertCell(cellIndex);
                  }
                }
                td.rowSpan = 1;                       
              }
            }
          }
        }
      }
      this.removeAllSelectedClass();
      this.initTdRc();//重新初始化过rc属性
      table.addEventListener('mousedown', this.onMousedown);
      // this.deleteColumn()
    },
    deleteColumn() {
      let col = parseInt(prompt('请输入原始列下标！'));
      if (isNaN(col) || col >= totalCell) { alert(`列下标需要介于0~${totalCell - 1}之间！`); return; }
      for (let tr of table.rows) {
        for (let td of tr.cells) {
          var rc = JSON.parse(td.getAttribute('rc'));
          if (rc.startCellIndex <= col && col <= rc.endCellIndex) {
            if (rc.startCellIndex == rc.endCellIndex) {//只有一个，删掉
              td.parentNode.removeChild(td);
            }
            else {
              td.colSpan -= 1;
            }
            break;//后续单元格不需要再遍历在，直接下一行
          }
        }
      }
      this.initTdRc();//重新初始化rc
    }
  },
}
</script>
<style lang="scss" >
.layout-diy{
  margin: 0 auto;
  .header-box {
    border: 1px solid #E1E1E1;
    .btn-box {
      background: #F8F8F8;
      padding:15px;
    }
    .table_box {
      padding: 20px 0;
        // height: 376px;
        // width: 100%;
        // overflow-x: scroll;
    }
  }
  .table-cell {
    border-collapse: collapse;
    margin:0 auto;
    td {
      border: 1px solid #ccc;
      width: 10px;
      height: 10px;
      box-sizing: border-box;
      word-break: break-all;
    }
    .selected {
      background: #0094ff;
      color: #fff
    }
  }
  .cannotselect {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }
  
}
</style>

