<template>
  <div class="layout-view">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }}</router-link>
      <span> > {{ $t('Layout Builder')}} > </span>
      <span style="color:#3461FF">{{ $t('Layout') }}</span>
    </div>

    <topbar>
      <div class="handleBtn">
        <div>
          <el-button type="primary" size="small"  @click="createGroup">
            <i class="el-icon-plus"></i>
            {{$t('button.New')}}
            </el-button>
          <el-button type="danger" size="small" :disabled="!selection()" @click="deleteStrategyConfirm" :title="$t('button.Delete')">
            <i class="el-icon-delete"></i>
            {{$t('button.Batch Delete')}}
          </el-button>
        </div>
        <!-- <div>
          <el-button type="info" size="small" @click="createGroup">
            Share To
          </el-button>
        </div> -->
      </div>
    </topbar>

    <div class="table_set card">
      <div class="select_filter">
        <div>
          <!-- <span class="text">Records:{{pageParams.total}}</span> -->
          <template v-if="userData.userType ===203 || userData.userType ===204">
            <el-select v-model="searchParams.storeQueryType" @change="layoutGetListApi" placeholder="" size="small">
              <el-option :label="$t('ThisStore')" value="2" />
              <el-option :label="$t('ZimenStore')" value="1" />
              <el-option :label="$t('AllStore')" value="0" />
            </el-select>
          </template>
        </div>
      </div>
      <el-table
        v-loading="loading"
        ref="treeTable"
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        :expand-row-keys="expandRrowKeys"
        @selection-change="handleSelectionChange"
        @select-all="handleSelectAll"
        @select="handleRowSelect"
        :cell-class-name="cellClassName"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        class="layout-list-table"
        table-layout="fixed"
        :max-height="500"
      >
      <!-- max-height="500" -->
        <el-table-column width="55" type="selection">
          <el-table-column type="selection"></el-table-column>
        </el-table-column>
        <el-table-column :label="$t('layout.Layout')">
          <el-table-column  prop="groupName" width="210">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.groupName" @input="layoutGetListApi" size="mini" placeholder="" clearable/>
            </template>
            <template slot="default" slot-scope="{row}">
              <span v-if="row.children.length ===0" style="width:23px;height:10px;display: inline-block;"></span>
              <span>{{row.groupName}}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('layout.Item Count')" header-align="center">
          <el-table-column prop="itemCount" min-width="100" align="center">
            <template slot="header" slot-scope="scope"></template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('layout.Layout Strategy')" min-width="150" header-align="center">
          <el-table-column prop="layoutStrategy" min-width="150" align="center">
            <template slot="header" slot-scope="scope">
              <el-select v-model="searchParams.strategy" placeholder="" size="mini" @change="handleStrategyChange" clearable >
                <el-option :label="$t('layout.Normal')" value="0" />
                <el-option :label="$t('layout.Split Screen')" value="1" />
                <el-option :label="$t('layout.Self-Adaption')" value="2" />
              </el-select>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('layout.Group Size')" header-align="center">
          <el-table-column  prop="groupSize"  min-width="96"  align="center">
            <template slot="header" slot-scope="scope">
              <el-select v-model="searchParams.groupSize" placeholder="" size="mini"  @change="handleModelChange" clearable>
                <el-option
                  v-for="item in AllTag"
                  :key="item.tagTypeCodeId"
                  :label="item.typeName"
                  :value="item.tagTypeCodeId"
                />
              </el-select>
            </template>
          </el-table-column>
        </el-table-column> 
        <el-table-column :label="$t('layout.Canvas Size')" header-align="center">
          <el-table-column prop="canvasSize" min-width="110" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.canvasSize" @input="layoutGetListApi" size="mini" placeholder="" clearable/>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('layout.Store Name')" header-align="center">
          <el-table-column prop="storeName" width="130" align="center">
            <template slot="header" slot-scope="scope">
              <!-- <el-input v-model="searchParams.canvasSize" @input="layoutGetListApi" size="small" placeholder="Canvas Size"/> -->
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('layout.Item Tag')" header-align="center">
          <el-table-column  prop="itemTag" width="100" align="center">
            <template slot="header" slot-scope="scope">
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('layout.Scenarios')" header-align="center">
          <el-table-column  prop="scenarioName" width="120" align="center">
            <template slot="header" slot-scope="scope">
              <el-select v-model="searchParams.scenario" placeholder="" size="mini"  @change="handleScenarChange" clearable>
                <el-option
                  v-for="item in store.scenarioList"
                  :key="item.scenarioId"
                  :label="item.translateTextName"
                  :value="item.scenarioId"
                />
              </el-select>
                <!-- <el-input v-model="pageParams.itemTag" @input="layoutGetListApi" size="small" placeholder="输入关键场景"/> -->
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('layout.Linked Template')" min-width="130" header-align="center">
          <el-table-column prop="templateTypeName" min-width="130" align="center"> </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('layout.Link More')" min-width="130">
          <el-table-column prop="linkMore" min-width="130" align="center"> 
            <template slot-scope="{row}">
              <!-- 布局层级--操作 -->
              <template v-if="row.type === 'layoutblock'">
                <div class="link-more" @click="handleLinkMore(row)">{{$t('layout.Link More')}}</div>
              </template>

            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('layout.Action')" width="150" header-align="center">
          <el-table-column width="150"  align="right"> 
            <template slot-scope="{row}" >

              <!-- 布局组层级--操作 -->
              <template  v-if="row.layoutGroupId">
                <span class="icon" @click="handleCreateStrategy(row)">
                  <!-- <icon-svg name="add"></icon-svg> -->
                  <i class="el-icon-circle-plus-outline"></i>
                </span>
                <span class="icon"  @click="handleDelGroup(row)" :title="$t('button.Delete')">
                  <i class="el-icon-delete"></i>
                </span>
                <span class="icon"  @click="createGroup(row)" :title="$t('button.Edit')">
                  <!-- <icon-svg name="edit" ></icon-svg> -->
                  <i class="el-icon-edit-outline"></i>
                </span>
              </template>
              
              <!-- 布局策略层级--操作 -->
              <template v-if="row.type === 'strategy'">
                <!-- 复制 -->
                <span class="icon"  @click="copyStrategy(row)" :title="$t('button.Copy')">
                  <i class="el-icon-document-copy"></i>
                </span>
                <!-- 自定义策略 有新增按钮 -->
                <template v-if="row.layoutStrategyType ===2">
                  <span class="icon" @click="createLayout(row)">
                    <!-- <icon-svg name="add"></icon-svg> -->
                    <i class="el-icon-circle-plus-outline"></i>
                  </span>
                </template>
                <span class="icon"  @click="handleDelStrategy(row)" :title="$t('button.Delete')">
                  <i class="el-icon-delete"></i>
                </span>
                <span class="icon"  @click="handleCreateStrategy(row)" :title="$t('button.Edit')">
                  <i class="el-icon-edit-outline"></i>
                </span>
                <!-- <span v-if="row.layoutStrategyId" class="icon"  @click="handleCreateStrategy(row)" :title="$t('button.Edit')">
                  <i class="el-icon-edit-outline"></i>
                </span> -->
              </template>

              <!-- 布局层级--操作 -->
              <template v-if="row.layoutId">
                <span class="icon" :title="$t('layout.Preview')" @click="previewLayout(row)">
                  <i class="el-icon-picture-outline"></i>
                </span>
                <span v-if="row.layoutStrategyType ===2" class="icon"  @click="delLayoutConfirm(row)" :title="$t('button.Delete')">
                  <i class="el-icon-delete"></i>
                </span>
                <span class="icon"  @click="editLayout(row)" :title="$t('button.Edit')">
                  <i class="el-icon-edit-outline"></i>
                </span>
              </template>

            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

    </div>
    <createGroup ref="createGroup" @layoutGetList="layoutGetListApi"></createGroup>
    <createStrategy ref="createStrategy" @layoutGetList="layoutGetListApi"></createStrategy>
    <createLayout ref="createLayout" :row="addLayout"></createLayout>
    <!-- linkMore弹窗 -->
    <relationTemplate ref="relationTemplate" :editData="linkMoreRow" :storeType="searchParams.storeQueryType"></relationTemplate>

    <!-- 布局预览 -->
    <layoutPreview ref="layoutPreview" :layoutLumpId="previewLayoutLumpId"></layoutPreview>

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="handleDel"></deleteConfirmDialog>

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog :content="$t('layout.tips')" ref="deleteConfirmDialog2" @onDeleteConfirm="handleDelConfirm"></deleteConfirmDialog>

  </div>
</template>

<script>
import { store } from "./store/index"
import vSelect from 'vue-select'
import topbar from '@/components/ui-topbar/index.vue'
import createGroup from './components/create-group.vue'
import createStrategy from './components/create-strategy.vue'
import createLayout from './components/create-layout.vue'
import relationTemplate from './components/relation-template.vue'
import layoutPreview from './components/layout-preview.vue'
import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'

import $Api from "@/libs/api/layout";
import {commonStore} from '@/store/global-select-store'

export default {
  components: {
    topbar,
    createGroup,
    createStrategy,
    vSelect,
    createLayout,
    relationTemplate,
    layoutPreview,
    deleteConfirmDialog
  },
  data() {
    return {
      commonStore,
      store,
      loading: true,
      addLayout:{},
      userData:JSON.parse(localStorage.getItem('userData')),
      searchParams:{
        strategy:'', //布局策略
        groupSize:'',
        canvasSize:'', //分屏尺寸
        scenario:'',
        scenarioId:'',
        groupName:'',
        storeQueryType:'2',
      },
      pageParams: {
        current:1,
        size:10,
        pageSizeArr:['10','20','50','100','200','500','1000'],
        storeId: '',
        total: 0
      },
      tableData: [],// 列表数据
      AllTag:[], // 价签类型列表--下拉数据源
      dialogVisible:false,
      delInfor:null, // 删除的数据信息
      linkMoreRow:{},
      previewLayoutLumpId:'',
      expandRrowKeys:['layoutId1595667804351377408','layoutStrategyId1595667804338794496'], // 默认展开的行
    }
  },
  created() {

  },
  mounted(){
    this.layoutGetListApi()
    this.getAllTagTypeApi()
    store.getScenarioList()
    commonStore.setInjectCallBack(this.layoutGetListApi)
    console.log('mounted',localStorage.getItem('layoutExpandRrowKeys'))
    if(localStorage.getItem('layoutExpandRrowKeys')){
      let ids = localStorage.getItem('layoutExpandRrowKeys')
      this.expandRrowKeys = ids.split(',')
    }

  },
  methods: {
    // 获取布局列表
    async layoutGetListApi(e){
      // let userData = JSON.parse(localStorage.getItem('userData'))
      // let storeId = userData.storeId
      this.loading = true
      let storeId = localStorage.getItem('storeId')
      localStorage.setItem('layoutStoreType',this.searchParams.storeQueryType)
      try  {
        const res = await $Api.layoutGetList({
          storeId, //门店id
          storeQueryType:  parseInt(this.searchParams.storeQueryType),//门店查询类型
          groupName: this.searchParams.groupName, //groupName
          groupSize: this.searchParams.groupSize, // groupSize
          scenarioId: this.searchParams.scenarioId,
          strategyName: '', 
          strategyType: this.searchParams.strategy, //布局策略类型
          itemCount: '', // 分屏数量
          canvasSize: this.searchParams.canvasSize,
          itemTag: '', //	item标签
          templateId: '', //模板id
          current: 1,
          size: 100,
          orders: []
        })
        if(!res.state){
          this.$message.error(res.message)
          this.loading = false
          return
        }
        const records = res.data
        this.tableData = records.map( it1 => {
          let groupObj = {  //布局组
            ...it1,
            type:'group',
            id: `groupId${it1.layoutGroupId}`, 
            groupName: it1.layoutGroupName,
            canvasSize: `${it1.layoutGroupWidth} * ${it1.layoutGroupHeight}`,
            groupSize: it1.layoutGroupSizeName,
            scenarioName:it1.scenarioName
            
          }
          let children = it1.children && it1.children.map( it2 =>  { //布局策略
            let strategyObj = {
              ...it2,
              type:'strategy',
              id: `layoutStrategyId${it2.layoutStrategyId}`, 
              oneLevelId:groupObj.id,
              groupName: it2.layoutStrategyName,
              layoutStrategy: this.switchStrategyType(`${it2.layoutStrategyType}`),
              scenarioName:it2.scenarioName,
              groupSize: it1.layoutGroupSizeName,
              canvasSize: `${it1.layoutGroupWidth} * ${it1.layoutGroupHeight}`,
              layoutStrategyType:it2.layoutStrategyType,
              parentLayoutGroupId:it1.layoutGroupId,
              layoutGroupWidth:it1.layoutGroupWidth,
              layoutGroupHeight:it1.layoutGroupHeight
            }
            let children =  it2.children &&  it2.children.map( it3  => { //布局
              let layoutObj = {
                ...it3,
                type:'layout',
                id:  `layoutId${it3.layoutId}`, 
                groupName: it3.layoutName,
                itemCount: it3.maxBind,
                strategyId: strategyObj.id,
                oneLevelId:groupObj.id,
                twoLevelId:strategyObj.id,
                scenarioName:it2.scenarioName,
                layoutStrategyType:it2.layoutStrategyType,
              }
              let children = it3.children && it3.children.map(it4 => {//布局块
                let layoutBlockObj = {
                  ...it4,
                  type:'layoutblock',
                  id: `layoutLumpId${it4.layoutLumpId}`, 
                  layoutId:it3.layoutId,
                  // groupName:`Layout Block ${it4.sort}` ,
                  groupName: this.$t('layout.Layout Block')+' '+it4.sort ,
                  canvasSize: `${it4.lumpWidth} * ${it4.lumpHeight} `,
                  itemTag : it4.itemTag ? this.$t('layout.Item')+it4.itemTag : it4.itemTag,
                  linkedTemplate : it4.templateName,
                  templateTypeName: it4.templateTypeName,
                  linkMore: '',
                  oneLevelId:groupObj.id,
                  twoLevelId:strategyObj.id,
                  parentLevelId:layoutObj.id,
                  scenarioName:it2.scenarioName,
                  scenarioId:it2.scenarioId,
                  layoutGroupName: it1.layoutGroupName,
                  layoutStrategyName:it2.layoutStrategyName,
                  layoutName:it3.layoutName,
                  layoutBlockName:`Layout Block ${it4.sort}` ,
                  // linkMore: ''
                }
                return { ...layoutBlockObj, children: []}
              })
              return { ...layoutObj, children } //布局
            })
            return { ...strategyObj, children }  //布局策略
          })
          return { ...groupObj, children} //布局组
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        const { message} = err;
        this.$message.error(message)
      }
    },
    // 获取价签类型列表
    async getAllTagTypeApi(){
      const { data } = await $Api.getAllTagType( { params:{}})
      console.log('所有的tags',data)
      store.setAllTags(data)
      this.AllTag = data
    },
    switchStrategyType(type){
      // console.log('转换展示类型',type)
      let str = this.$t('layout.Normal')
      switch (type) {
        case '0':
          str = this.$t('layout.Normal')
          break
        case '1':
          str = this.$t('layout.Split Screen')
          break
        case '2':
          str =this.$t('layout.Self-Adaption')
          break
      }
      return str
    },
    // 点击---link more
    handleLinkMore(data){
      console.log('handleLinkMore',data)
      this.linkMoreRow = data
      this.$nextTick(() => {
        this.$refs.relationTemplate.open(data)
      })
      
    },
    // 布局组---删除
    handleDelGroup(row){
      this.deleType='group'
      this.$refs.deleteConfirmDialog.open()
      // this.dialogVisible = true
      this.delInfor = row
      console.log(row)
    },
    // 布局组---删除--校验
    async checkGroupUsing(){
      try {
        const res = await $Api.checkGroupUsing({
          layoutGroupIds:this.delInfor.layoutGroupId
        })
        console.log('checkGroupUsing---res',res)
        if(!res.state){
          this.$message.error(res.message)
          return
        }
        if(res.data){
          // 正在使用中
          this.$refs.deleteConfirmDialog2.open()
        } else {
          // 未使用
          this.deleteGroup()
        }
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
      
    },
    // 布局组---删除请求接口
    async deleteGroup(){
      this.loading = true
      try  {
        const res = await $Api.physicalDeleteGroup({
          layoutGroupId:this.delInfor.layoutGroupId
        })
        if(res.state){
          this.$message({
            message: this.$t('commonMessage.DeleteSucess'),
            type:'success',
          });
          this.$refs.deleteConfirmDialog.close()
          this.$refs.deleteConfirmDialog2.close()
          this.layoutGetListApi()
        } else {
          this.$message({
            message: res.message,
            type:'warning',
          });
        }
        setTimeout(() => {
          this.loading = false
        },500)
      } catch (err) {
        this.$message.error(err.message)
        this.loading = false
      } 
    },
    // 筛选---布局策略变更
    handleStrategyChange(val){
      console.log(val)
      this.searchParams.strategy = val
      this.layoutGetListApi()
    },
    // 筛选---model变更
    handleModelChange(val){
      console.log('model',val)
      this.searchParams.groupSize = val
      this.layoutGetListApi()
    },
    // 筛选---场景变化
    handleScenarChange(val){
      this.searchParams.scenarioId = val
      this.layoutGetListApi()
    },
    // 策略---复制
    async copyStrategy(row){
      console.log('复制策略',row)
      const res = await $Api.copyStrategy({layoutStrategyId:row.layoutStrategyId})
      console.log('复制策略',res)
      if(res.state){
        this.layoutGetListApi()
        // loading.close()
      } else{
        // loading.close()
        this.$message.error(res.message)
      }
      
    },
    // 策略--添加/编辑
    handleCreateStrategy(row){
      console.log('添加修改--布局策略',row)
      localStorage.setItem('layoutExpandRrowKeys',row.id)
      this.$refs.createStrategy.openDialog(row)
    },
    // 策略--批量删除弹窗-二次确认
    deleteStrategyConfirm(){
      this.deleType='strategy'
      // this.dialogVisible = true
      this.$refs.deleteConfirmDialog.open()

      const selection = this.$refs.treeTable.selection
      console.log('批量删除----',selection)
      // let layoutStrategyIds = selection.map(it => it.layoutStrategyId)
      let layoutStrategyIds = []
      selection.forEach(item => {
        if(item.layoutStrategyId){
          layoutStrategyIds.push(item.layoutStrategyId)
        }
      })
      let delInfor={
        layoutStrategyIds:layoutStrategyIds.join()
      }
      this.delInfor = delInfor
    },
    // 策略 -- 单个删除-弹窗确认
    handleDelStrategy(row){
      this.deleType='strategy'
      // this.dialogVisible = true
      this.$refs.deleteConfirmDialog.open()

      let delInfor={
        layoutStrategyIds:row.layoutStrategyId
      }
      this.delInfor = delInfor
      console.log(delInfor)
    },
    // 策略---批量删除--校验
    async checkStrategyUsing(){
      try {
        const res = await $Api.checkStrategyUsing({layoutStrategyIds:this.delInfor.layoutStrategyIds})
        if(!res.state){
          this.$message.error(res.message)
          return
        }
        if(res.data){
          // 正在使用中
          this.$refs.deleteConfirmDialog2.open()
        } else {
          // 未使用
          this.batchPhysicalDeleteStrategy()
        }
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 策略---批量-删除请求接口
    async batchPhysicalDeleteStrategy(){
      let delInfor =this.delInfor
      // const selection = this.$refs.treeTable.selection
      // let layoutStrategyIds = selection.map(it => it.layoutStrategyId)
      // console.log('into', this.$refs.treeTable.selection)
      await $Api.batchPhysicalDeleteStrategy({layoutStrategyIds: delInfor.layoutStrategyIds});
      this.$message({
        message: this.$t('commonMessage.DeleteSucess'),
        type:'success',
      });
      // this.dialogVisible = false
      this.$refs.deleteConfirmDialog.close()
      this.$refs.deleteConfirmDialog2.close()
      this.layoutGetListApi()

    },
    // 复选框 选中数量
    selection() {
      let isChecked = false
      if(this.$refs.treeTable && this.$refs.treeTable.selection.length){
        console.log('选中的',this.$refs.treeTable.selection)
        let layoutStrategyIds = []
        let selection = this.$refs.treeTable.selection
        selection.forEach(item => {
          if(item.layoutStrategyId){
            layoutStrategyIds.push(item.layoutStrategyId)
          }
        })
        isChecked = layoutStrategyIds.length
      }

      return isChecked
      
      // return this.$refs.treeTable && this.$refs.treeTable.selection.length
    },
    createGroup(row){
      this.$refs.createGroup.show(row)
    },
    // 布局---添加布局（仅限自定义布局策略下）
    createLayout(row){
      let arr =[row.oneLevelId,row.id]
      let ids = arr.join(',')
      localStorage.setItem('layoutExpandRrowKeys',ids)
      let obj={
        ...row,
        layoutGroupId:row.parentLayoutGroupId
      }
      console.log('自定义布局策略---添加布局',obj)
      this.addLayout = obj
      this.$nextTick(() => {
        this.$refs.createLayout.open()
      })
      
    },
    // 布局---预览
    previewLayout(row){
      // if(row.layoutLumpId){
      //   // 布局块预览
      //   this.previewLayoutLumpId = row.layoutLumpId
      //   this.$nextTick(() => {
      //     this.$refs.layoutPreview.open(row)
      //   })
      // }
      this.$refs.layoutPreview.open(row)
      console.log('预览布局、布局块',row)
    },
    // 布局--编辑
    editLayout(row){
      console.log('row==', row)
      let arr = [row.oneLevelId,row.twoLevelId]
      if(row.type === 'layoutblock'){
        arr = [...arr,row.parentLevelId]
      } 
      // else {
      //   arr = [...arr,row.id]
      // }
      let ids= arr.join(',')
      console.log('缓存当前的ids',ids)
      localStorage.setItem('layoutExpandRrowKeys',ids)
      const { layoutId, strategyId } = row
      this.$router.push({
        name: 'layout-edit',
        params: {
          layoutId,
          layoutStrategyId: strategyId
        }
      })
    },
    // 布局--删除确认
    delLayoutConfirm(row){
      this.delInfor=row
      // this.dialogVisible = true
      this.$refs.deleteConfirmDialog.open()
      this.deleType='layout'
    },
    // 布局---删除校验
    async checkLayoutUsing(){
      try {
        this.$refs.deleteConfirmDialog.close()
        const res = await $Api.checkLayoutUsing({layoutIds:this.delInfor.layoutId})
        if(!res.state){
          this.$message.error(res.message)
          return
        }
        if(!res.data){
          // 正在使用中
          this.$refs.deleteConfirmDialog2.open()
        } else {
          // 未使用
          this.delLayout()
        }
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 布局--删除请求接口
    async delLayout(){
      this.loading = true
      const res = await $Api.physicalDelete({
        layoutId:this.delInfor.layoutId
      })
      if(res.state){
        this.$message({
          message: this.$t('commonMessage.DeleteSucess'),
          type:'success',
        });
        // this.dialogVisible = false
        this.$refs.deleteConfirmDialog.close()
        this.$refs.deleteConfirmDialog2.close()
        this.layoutGetListApi()
      } else {
        this.$message.error(res.message)
      }
      setTimeout(() => {
        this.loading = false
      },500)
      
      
    },
    // 删除弹窗--确认
    handleDel(){
      if(this.deleType === 'group'){
        // 删除自定义策略下的布局
        this.checkGroupUsing()
      } else if(this.deleType === 'layout'){
        // 删除自定义策略下的布局
        this.checkLayoutUsing()
      } else {
        // 批量删除布局策略
        this.checkStrategyUsing()
      }
    },
    // 正在使用中-二次确认
    handleDelConfirm(){
      if(this.deleType === 'group'){
        // 删除自定义策略下的布局
        this.deleteGroup()
      } else if(this.deleType === 'layout'){
        // 删除自定义策略下的布局
        this.delLayout()
      } else {
        // 批量删除布局策略
        this.batchPhysicalDeleteStrategy()
      }
    },
    importFile(){

    },
    pageChange(value, props){
      console.log('pageChange', value, props)
      this.pageParams.size = value
      this.layoutGetListApi()
    },
    cellClassName(cell){
      const { columnIndex,  row, rowIndex} = cell
      // console.log('cell===', cell)
      return columnIndex == 0 && !row.layoutStrategyId  ? 'hideClass' : ''
    },
     // 规则适用范围全选
    handleSelectAll(selection) {
      const isAllSelected = this.$refs.treeTable.store.states.isAllSelected
      console.log('selection==', this.$refs.treeTable.store.states.isAllSelected)
      if(isAllSelected) {
        const arr = []
        selection.forEach( it => {
          arr.push(...it.children)
        })
        this.selectChild(arr)
      } else {
        this.$refs.treeTable.clearSelection();
      }
    },
    handleRowSelect(){
      
    },
    handleSelectionChange(selection) {

    },
    selectChild(data){
      if(!data) return
      data.forEach( it => {
        this.$refs.treeTable.toggleRowSelection(it);
        // this.selectChild(it.children)
      })
    },
    changeCurrent(current){
      this.pageParams.current = current
      this.layoutGetListApi()
    },
    async updateCoorStore(){
      const selectedPageRows = this.$refs.goodTable.selectedPageRows
      const ids = selectedPageRows.map(it => it.coorMacAddressId)
      const res = await this.$Api.updateCoorStore({ ids:ids.join(','), merchantId })
    }
  },
}
</script>
<style lang="scss" scoped>
  .layout-view{
    .location{
      margin-bottom: 20px;
    }
    .hd{
      margin-bottom: 20px;
    }
    .link-more{
      padding: 2px 16px;
      background: #C1E9FF;
      color: #409EFF;
      border-radius: 14px;
      display: inline-block;
      cursor: pointer;
    }
    .layout-list-table{
      // max-height: calc( 100vh - calc(3.49rem + 3.32rem) );
    }
    .handleBtn {
      display: flex;
      justify-content: space-between;
    }
    ::v-deep .hideClass {
      visibility: hidden
    }
    .icon  {
      padding:6px;
      cursor: pointer;
      font-size: 18px;
      color: #000;
      .svg-icon {
        width: 18px;
        height: 18px;
      }
    }
    .text {
      padding: 0 15px;
    }
    .table_set {
      .select_filter {
        padding: 15px;
        .table_label_size {
          width: 160px;
          font-size: 16px;
        }
        .table_select {
          width: 214px;
        }
      }
      .b_pag {
        padding: 0 35px
      }
      .tb_checkbox {
        vertical-align: middle;
      }
    }
    .b_pag{
      font-size: 14px;
    }

    // 重置样式
    .el-table th.el-table__cell>.cell{
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .el-table tr:first-child th.el-table__cell{
      font-size: 14px;
    }
  }
  .layout-view ::v-deep {
    .el-table
      .el-table__header-wrapper
      .el-table__header
      .is-group
      tr:nth-of-type(2)
      th:nth-of-type(1)
      .cell {
      display: none;
    }
  }
</style>