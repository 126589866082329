<template>
  <div class="create-group">
    <el-dialog
      :title="$t('layout.Create Group')"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      width="550px"
    >
      <div class="form-box">
        <el-form 
          :model="formData" 
          :rules="rules" 
          ref="createGroup" 
          label-position="right" 
          label-width="120px"
          size="small"
        >
            <el-form-item :label="$t('layout.Group Name')" prop="groupName">
              <el-input v-model="formData.groupName" clearable></el-input>
            </el-form-item>
            <!-- :disabled="!formData.layoutGroupId" -->
            <el-form-item :label="$t('layout.Canvas Size')" prop="layoutGroupSize">
              <el-select  v-model="formData.layoutGroupSize" placeholder="" :disabled="!!formData.layoutGroupId">
                <el-option
                  v-for="item in AllTag"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  <span>{{ item.size }}</span>
                  <span style="margin-left: 20px;">{{ item.typeName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('layout.Is Rotate')" prop="isRotate" class="margin_b0" >
              <el-radio-group v-model="formData.isRotate" :disabled="formData.layoutGroupId">
                <el-radio :label="0">{{$t('layout.No Rotate')}}</el-radio>
                <el-radio :label="1">{{$t('layout.Yes Rotate')}}</el-radio>
              </el-radio-group>
            </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm('ruleForm')">{{$t('button.Cancel')}}</el-button>
        <el-button type="success" size="small" @click="submitForm">{{$t('button.Save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { store } from "../store/index"
  import $Api from "@/libs/api/layout";
  export default {
    data() {
      return {
        store,
        formData: {},
        AllTag: [],
        dialogVisible: false,
         ruleForm: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        rules: {
          groupName: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 50, message: this.$t('formValidate.maxLength50'), trigger: 'blur'}
          ],
          layoutGroupSize: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'change' }
          ],
          isRotate:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'change' }
          ],
        }
      };
    },
    created(){
      
    },
    methods: {
      // 关闭弹窗
      handleClose(){
        this.$refs.createGroup.resetFields()
        this.dialogVisible = false
      },
      submitForm() {
        this.$refs.createGroup.validate((valid) => {
          if (valid) {
            this.createGroup()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      show(row){
        console.log('row===', row)
        let newData ={...row}
        if(newData.layoutGroupId){
          this.formData = newData
          console.log(newData.isRotate)
          console.log(newData.isRotate == 1)
          this.formData.isRotate = newData.isRotate == 1 ? 1:0
        }else{
          this.formData = {}
        }
        this.dialogVisible = true
        this.getAllTagType()
      },
      // 创建、修改布局组
      async createGroup(){
        const { layoutGroupId, groupName, layoutGroupSize, isRotate } = this.formData
        console.log('layoutGroupId, layoutGroupName', layoutGroupId, layoutGroupSize,isRotate)
        if(layoutGroupId){
          await $Api.updateGroup({ layoutGroupId, layoutGroupName: groupName, isRotate })
          this.dialogVisible = false
          this.$emit('layoutGetList')
        } else {
          let params ={
            groupName, 
            layoutSize : layoutGroupSize,
            isRotate,
            storeId:localStorage.getItem('storeId')
          }
          await $Api.createGroup(params)
          this.dialogVisible = false
          this.$emit('layoutGetList')
        } 
       
      },
      // 获取尺寸
      async getAllTagType(){
        let userData=JSON.parse(localStorage.getItem('userData'))
        let params = {
          merchantId: userData.merchantId,
          source:'1'
        }
         const { data } = await $Api.getAllTagType(params)
         console.log('所有的tags',data)
         store.setAllTags(data)
         this.AllTag = data.map( it => {
          return {
            value: it.tagTypeCodeId + '',
            id:it.tagTypeCodeId,
            label: `${it.typeWidth} * ${it.typeHeight}  ${it.typeName}`,
            size:`${it.typeWidth} * ${it.typeHeight}`,
            typeName:it.typeName
          }
         }) || []
      },
      
      resetForm() {
        this.dialogVisible = false
        // this.$refs[formName].resetFields();
      },
    }
  };
</script>
<style lang="scss" scoped>
  .create-group{
    .form-box{
      width: 400px;
      padding: 30px 0;
      margin: 0 auto;
    }
    .margin_b0 {
      margin-bottom: 0;
    }
    // .el-input {
    //   width: 434px;
    // }
    ::v-deep .el-button{
      width: 110px;
    }
    .el-select{
      width: 100%;
    }
    .el-button--success{
      background: #35B871;
    }
  }

</style>
