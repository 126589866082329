import { reactive } from '@vue/composition-api'
import { getScenarioSimplifyList } from "@/libs/api/scenario-config";
import { Message } from 'element-ui';

export const store = reactive({
  allTags:[],
  setAllTags(data){
    this.allTags = data
  },
  scenarioList:[],
  // 获取场景列表数据
  async getScenarioList(){
    let userData = JSON.parse(localStorage.getItem('userData'));
    let params = {
      merchantId: userData.merchantId,
      textKey:"",
      scenarioKey: "",
      isEffect: 0,
      agentId: ""
    }
    let { data } = await getScenarioSimplifyList(params)
    if(data.state){
      this.scenarioList = data.data
    } else {
      Message.error(data.message);
    }
  },
})