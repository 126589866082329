<template>
  <div class="create-store">
    <el-dialog 
      v-model="dialogVisible" 
      :title="$t('layout.Related Template')" 
      :before-close="handleClose" 
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="700px"
    >
      <div class="form-box">
        <el-form 
          label-width="160px"
          style="width: 100%"
          :model="formData"
          ref="form" 
          :rules="rules"
          size="small"
        >
        <!-- *Layout *Layout Block -->
          <el-form-item :label="$t('layout.Group Name')" prop="layoutGroupName">
            <el-input  v-model="formData.layoutGroupName" disabled />
          </el-form-item>
          <el-form-item :label="$t('layout.Layout Strategy')" prop="layoutStrategyName">
            <el-input  v-model="formData.layoutStrategyName" disabled />
          </el-form-item>
          <el-form-item :label="$t('layout.Layout')" prop="layoutName">
            <el-input  v-model="formData.layoutName" disabled />
          </el-form-item>
          <el-form-item :label="$t('layout.Layout Block')" prop="groupName">
            <el-input  v-model="formData.groupName" disabled />
          </el-form-item>
          <el-form-item :label="$t('layout.Link More')">
            <div class="relation-template-item" v-for="(item,index) in linkMore" :key="item.templateTypeId">
              <el-select 
                style="margin-right:10px" 
                v-model="linkMore[index].templateTypeId" 
                placeholder="" size="small" 
                clearable  
                @change="e => handleModelTypeChange(e,index)"
              >
                <!--  @change="handleModelTypeChange"  -->
                <el-option
                  v-for="(item,index) in templateTypeList"
                  :key="index"
                  :label="item.templateTypeName"
                  :value="item.templateTypeId"
                  :disabled="item.disabled"
                />
              </el-select>
              <el-select 
                style="margin-right:10px" 
                v-model="linkMore[index].templateName"  
                @focus="selectTemplate(index)" 
                placeholder="" 
                readonly 
                size="small" 
                clearable
                :disabled="linkMore[index].templateTypeId === ''"
              >
              </el-select>
              <template v-if="index === 0">
                <el-button type="primary" size="small" @click="handleAddTemplate" :disabled="linkMore.length === templateTypeList.length ">
                  <span style="padding:0 2px">
                    <i class="el-icon-plus"></i>
                  </span>
                </el-button>
              </template>
              <template v-else>
                <el-button type="primary" size="small" @click="handleDeleteTemplate(index)">
                  <span style="padding:0 2px">
                    <i class="el-icon-delete"></i>
                  </span>
                </el-button>
              </template>
              
            </div>
            
          </el-form-item>

          <!-- 保存按钮 -->
          <div class="form-btn-section">
            <el-button color="#28C76F" size="small" @click="handleClose" >{{$t('button.Cancel')}}</el-button>
            <el-button color="#28C76F" size="small"  type="success" @click="onSubmit" >{{$t('button.Save')}}</el-button>
          </div>

        </el-form>
      </div>
    </el-dialog>

    <!-- 选择模板 -->
    <selectTemplate 
      ref="selectTemplate" 
      :showRadio="true"  
      :width="editData.lumpWidth" 
      :height="editData.lumpHeight"
      :scenarioId = "scenarioId"
      :storeType="storeType"
      :templateTypeId="currentTemplateTypeId"
      :templateTypeDisabled="true"
      @onTemplateConfirm="handleTemplateConfirm"
      >
    </selectTemplate>
  </div>
</template>
<script>
  import selectTemplate from '@/components/select-template/index'
  import $Api from "@/libs/api/layout";

  let loading;

  export default {
    name:'createCustomer',
    components: {
      selectTemplate
    },
    props: {
      editData:{
        type:Object
      },
      storeType:{
        type: String,
        default: '',
      }
    },
    data() {
      return {
        userData:JSON.parse(localStorage.getItem('userData')),
        dialogVisible:false,
        formData:{
          layoutGroupName:'',
          layoutStrategyName:'',
          layoutName:'',
          groupName:'',
          layoutLumpId:''
        },
        templateTypeList:[],
        // templateTypeId:'',
        // templateId:'',
        rules:{
          roleName: [
            { required: true, message: 'User Group 必填', trigger: 'blur' },
            { max: 100, message: '最长不能超过100个字符', trigger: 'blur'}
          ], 
        },
        linkMore:[
          // {
          //   templateId:'',
          //   templateName:'',
          //   templateTypeId:'',
          // }
        ],
        currentIndex:0, // 当前操作绑定的 index
        currentTemplateTypeId:'',
        scenarioId:''
      }
    },
    created(){

    },
    mounted(){
      
    },
    watch: {
      editData: function(val) {
        console.log('--watch--editData',val)
        let data ={
          ...val,
        }
        this.formData = data
      }
    },
    methods: {
      // 打开弹窗
      open(linkMoreData){
        this.dialogVisible = true
        this.scenarioId = linkMoreData.scenarioId
        this.getData(linkMoreData)
      },
      // 关闭弹窗
      handleClose(){
        this.linkMore = []
        this.dialogVisible = false
      },
      // 获取数据
      async getData(linkMoreData){
        await this.getLayoutLinkMore(linkMoreData)
        await this.getTemplateTypeList(linkMoreData)
      },
      // 获取数据---link more 
      async getLayoutLinkMore(linkMoreData){
        // let editData = this.editData
        const { data } = await $Api.getLayoutLinkMore( { layoutLumpId:linkMoreData.layoutLumpId})
        if(data.length >0 ){
          this.linkMore = data
        }else {
          this.linkMore = []
        }
        console.log('获取link more 数据',data)
      },
      // 获取数据---模板类型
      async getTemplateTypeList(linkMoreData){
        let linkMore =this.linkMore
        let res = await $Api.templateTypeGetList({
          scenarioId:linkMoreData.scenarioId,
          storeId:localStorage.getItem('storeId')
        })
        if(res.state){
          let dataList = res.data
          this.templateTypeList = dataList
          if(linkMore.length === 0){
            let linkMore = dataList.map(item => {
              let obj={
                templateTypeId:item.templateTypeId,
                templateId:'',
                templateName:'',
              }
              return obj
            })
            this.linkMore = linkMore
          } else {
            this.handleModelTypeChange()
          }

        } else {
          this.$message.error(res.message)
        }
        
      },
      // 模板类型--change
      handleModelTypeChange(val,index){
        console.log('模板类型--change-----',val,index)
        let linkMore= this.linkMore
        if((val !== undefined && !val) || val){
          // 清除了类型  -- 或  切换了类型
          linkMore[index].templateId =''
          linkMore[index].templateName =''
        }
        this.linkMore = linkMore
        this.handleCheckItem()
      },
      // 模板下拉类型是否可选--处理
      handleCheckItem(){
        let linkMore = this.linkMore
        let templateTypeList = [...this.templateTypeList]
        templateTypeList.forEach( element => {
          element.disabled = false
          var arr = linkMore.filter(item => item.templateTypeId === element.templateTypeId)
          if(arr.length >0){
            element.disabled = true
          }
        });
        console.log('----templateTypeList',templateTypeList)
        this.templateTypeList = templateTypeList

      },
      // 选择模板--弹窗
      selectTemplate(index){
        this.currentIndex = index
        this.currentTemplateTypeId = this.linkMore[index].templateTypeId
        this.$refs.selectTemplate.show()
      },
      // 选择模板--确定
      handleTemplateConfirm(obj){
        console.log('绑定模板成功',obj)
        let linkMore = [...this.linkMore]
        linkMore[this.currentIndex].templateId = obj.templateId
        linkMore[this.currentIndex].templateName = obj.templateName
        this.linkMore = linkMore
      },
      // 添加模板
      handleAddTemplate(){
        let linkMore = this.linkMore
        linkMore.push({
          templateId:'',
          templateName:'',
          templateTypeId:'',
        })
      },
      // 删除添加
      handleDeleteTemplate(index){
        let linkMore = this.linkMore
        linkMore.splice(index,1)
        this.handleCheckItem()
      },
      // 确定
      // 表单提交
      onSubmit(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$refs.form.validate( valid => {
          if (valid) {
            console.log('表单校验通过',)
            this.handleLayoutLinkMore()
          } else {
            loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 请求--- 创建用户组/更新用户组
      async handleLayoutLinkMore(){
        let templateIdList=[]
        let linkMore= this.linkMore
        linkMore.forEach(element => {
          if(element.templateId){
            templateIdList.push(element.templateId)
          }
        });
        let params = {
          layoutLumpId:this.formData.layoutLumpId,
          templateIdList
        }
        try  {
          let res = await $Api.layoutLinkMore(params)
          if(res.state){
            this.$message({
              message: this.$t('layout.SucessMessage1'),
              type:'success',
            });
            this.dialogVisible = false
          } else {
            this.$message.error(res.message)
          }
          loading.close()
        } catch (err) {
          loading.close()
        }
        
        
      },
    }
  }
</script>
<style lang="scss" scoped>
  .create-store{
    .form-box{
      width: 480px;
      margin: 0 auto;
      min-height: 300px;
    }
    .relation-template-item{
      display: flex;
      margin-bottom: 10px;
    }
    .form-btn-section{
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      ::v-deep .el-button{
        width: 110px;
      }
    }
    // 重置样式
    ::v-deep .el-dialog{
      margin-top: 5vh !important;
    }
    ::v-deep .el-dialog__body{
      max-height: calc(82vh);
      overflow-y: scroll;
    }
    .el-select{
      width: 100%;
    }
    .el-button--success{
      width: 100%;
    }
  }
</style>