<template>
  <div class="layout-set">
    <div class="header-box">
      <div class="btn-box">
      <el-form ref="form" :model="splitForm" label-width="80px" size="mini" inline>
        <el-form-item :label="$t('layout.Rows')">
          <el-input v-model="splitForm.rowsInput" @input="handleInput"></el-input>
        </el-form-item>
          <el-form-item :label="$t('layout.Columns')">
          <el-input v-model="splitForm.columnsInput" @input="handleInput"></el-input>
        </el-form-item>
      </el-form>
      </div>
      <div class="table_box" v-if="flag">
        <div 
          class="layout-set-box" 
          :style="{
            width: row.layoutGroupWidth*scale+'px',
            height: row.layoutGroupHeight*scale+'px',
          }"
        >
          <template v-if="count>0">
            <div 
              v-for="(item,index) in count" 
              class="layout-set-section"
              :key="index"
              :style="{
                width: itemWidthScale+'%',
                height: itemHeightScale+'%',
              }"
            >
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "layoutDiy",
    props: {
      row: {
        type: Object,
        default: () => {}
      }
    },
    components: {
    },
    data() {
      return {
        scale:1,
        flag: true,
        splitForm: {
          rowsInput: '',
          columnsInput: ''
        },
        layout: [
          {
            endCellIndex: 5,
            endRowIndex: 2,
            startCellIndex: 1,
            startRowIndex: 1,
            sort:1
          }
        ],
        count:0,//共计几个单元格
        itemWidthScale:'',
        itemHeightScale:'',
        columns:1,
        rows:1,
      }
    },
    created() {
      console.log('this.row.layoutGroupWidth--',this.row.layoutGroupWidth)
      this.calScale(this.row.layoutGroupWidth)
    },
    mounted(){
      
    },
    watch: {
      row: function(val) {
        console.log('---watch-按行列-',val)
        this.calScale(val.layoutGroupWidth)
      }
    },
    methods: {
      calScale(width){
        console.log('计算比例',width)
        let scale = 1
        switch (width) {
          case 200:
            // 200×200
            scale = 1.2
            break
          case 250:
            // 250×120
            scale = 2
            break
          case 296:
            // 296×152
            // 296×128
            scale = 1.8
            break
          case 384:
            // 384×184
            scale = 1.5
            break
          case 240:
            // 240×416
            scale = 0.6
            break
          case 400:
            // 400×300
            scale = 0.8
            break
          case 648:
            // 648×480
            scale = 0.5
            break
          case 880:
            // 880×528
            scale = 0.48
            break
          case 800:
            // 800×480
            scale = 0.56
            break
          case 960:
            // 960×640
            scale = 0.4
            break
        }
        console.log('计算比例------scale',scale)
        this.scale = scale
      },
      // 行列--inputChange
      handleInput(){
        console.log('输入的行和列：',this.splitForm.rowsInput,this.splitForm.columnsInput)
        let {rowsInput,columnsInput} = this.splitForm
        let itemWidthScale,itemHeightScale,columns=1,rows=1,count = 0

        columns = columnsInput
        rows = rowsInput
        if(!columnsInput){
          columns =1
        }
        if(!rowsInput){
          rows = 1
        }
        
        count = rows*columns
        itemHeightScale = 100/rows
        itemWidthScale = 100/columns

        this.itemWidthScale =  itemWidthScale
        this.itemHeightScale = itemHeightScale
        this.count = parseInt(count)
        this.rows = parseInt(rows)
        this.columns =  parseInt(columns)
        console.log('行列',parseInt(count), parseInt(rows),parseInt(columns))
      },
      // 生成坐标点
      getLayout(){
        let {rows,columns} = this
        let layoutBlock = []
        let itemWidth = Math.ceil(this.row.layoutGroupWidth / columns)
        let itemHeight =  Math.ceil(this.row.layoutGroupHeight / rows)
        for(var i=0;i<rows;i++){
          // startCellIndex,起始X坐标，endCellIndex，终点X坐标
          // startRowIndex,起始Y坐标，endRowIndex，终点Y坐标
          for(var j=0;j<columns;j++){
            let obj = {
              startCellIndex: j*itemWidth,
              endCellIndex:(j+1)*itemWidth,
              startRowIndex: i*itemHeight,
              endRowIndex: (i+1)*itemHeight,
              sort:(j+1)+i*columns
            }
            layoutBlock.push(obj)
          }
        }
        console.log('layoutBlock==', layoutBlock)
        return layoutBlock
      }
    }
  }
</script>
<style lang="scss" lang="scss" scoped>
  .layout-set{
    ::v-deep .el-dialog {
      margin-top: 5vh!important;
    }
    .header-box {
      border: 1px solid #E1E1E1;
      .btn-box {
        background: #F8F8F8;
        padding:15px 15px 0 15px;
      }
      .table_box {
        height: 300px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // overflow-x: scroll;
        table {
          border-collapse: collapse;
          border: 1px solid #ccc;
        }
        table td {
        // padding: 2px;
          width: 1px;
          height: 1px;
          word-break: break-all;
        }
      }
      .layout-set-box{
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #E1E1E1;
        .layout-set-section{
          border-right:1px solid #E1E1E1;
          border-bottom: 1px solid #E1E1E1;
        }
      }
    }
    .cannotselect {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -khtml-user-select: none;
        user-select: none;
    }
    td.selected {
        background: #0094ff;
        color: #fff
    }
  }
</style>

